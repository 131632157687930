import React from "react";
import FacebookEmbed from "../FacebookEmbed";
import InstagramEmbed from "../InstagramEmbed";
import TikTokEmbed from "../TikTokEmbed";
import YoutubeEmbed from "../YoutubeEmbed";

function SocialMediaPage() {
  return (
    <div className="social-media-page">
      <h2>Social Media</h2>
      <div className="embedded-feeds">
        <div className="side-by-side-embed">
          <div className="row">
            <div className="col-md-6">
              <InstagramEmbed />
            </div>
            <div className="col-md-6">
              <FacebookEmbed />
            </div>
          </div>
        </div>
        <TikTokEmbed />
        <YoutubeEmbed />
      </div>
    </div>
  );
}

export default SocialMediaPage;
