import React from "react";

function InstagramEmbed() {
  return (
    <div className="instagram-embed">
      <iframe
        src="https://www.instagram.com/p/CoDe1wJpnG2/embed/"
        title="Instagram video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default InstagramEmbed;
