import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

function ContactHeader() {
  return (
    <div className="contact-header-component">
      <div>
        <FontAwesomeIcon icon={faEnvelope} className="contact-header-icon" />
        <p>
          <a href="mailto:joseph@josephbertone.com">joseph@josephbertone.com</a>
        </p>
      </div>
      <div>
        <FontAwesomeIcon icon={faPhone} className="contact-header-icon" />
        <p>
          <a href="tel:4374889685">437-488-9685</a>
        </p>
      </div>
    </div>
  );
}

export default ContactHeader;
