import React from "react";
import Calendly from "../Calendly";

function BookAppointmentPage() {
  return (
    <div className="book-appointment-page">
      <h2>Book an Appointment</h2>
      <Calendly />
    </div>
  );
}

export default BookAppointmentPage;
