import React from "react";

function TikTokEmbed() {
  return (
    <div className="tik-tok-embed">
      <blockquote
        class="tiktok-embed"
        cite="https://www.tiktok.com/@josephbertonerealestate"
        data-unique-id="josephbertonerealestate"
        data-embed-type="creator"
        style={{ maxWidth: "780px", minWidth: "288px" }}
      >
        <section>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.tiktok.com/@josephbertonerealestate?refer=creator_embed"
          >
            @josephbertonerealestate
          </a>
        </section>
      </blockquote>
    </div>
  );
}

export default TikTokEmbed;
