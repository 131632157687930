import React from "react";

function YoutubeEmbed() {
  return (
    <div className="youtube-embed">
      <iframe
        width=""
        height="315"
        src="https://www.youtube.com/embed/42DYfkZWUS4"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default YoutubeEmbed;
