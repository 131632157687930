import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTiktok,
  faYoutube,
  faFacebook,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

function FindMe() {
  return (
    <div className="find-me-component">
      <h4>Feel free to call, text or email if you have any questions,</h4>
      <h4>check on a listing or just want to say hello.</h4>
      <h4>I'll be happy to help!</h4>
      <br />
      <br />
      <h4>You can also find me here!</h4>
      <div className="social-icon-links-container">
        <a
          href="https://instagram.com/josephbertonerealestate"
          target="_blank"
          rel="noreferrer"
          className="social-icon-link"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          href="https://tiktok.com/@josephbertonerealty"
          target="_blank"
          rel="noreferrer"
          className="social-icon-link"
        >
          <FontAwesomeIcon icon={faTiktok} />
        </a>
        <a
          href="https://youtube.com/@Josephbertonerealestate"
          target="_blank"
          rel="noreferrer"
          className="social-icon-link"
        >
          <FontAwesomeIcon icon={faYoutube} />
        </a>
        <a
          href="https://facebook.com/profile.php?id=100088890105622"
          target="_blank"
          rel="noreferrer"
          className="social-icon-link"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a
          href="https://linkedin.com/in/joseph-bertone-760325122/"
          target="_blank"
          rel="noreferrer"
          className="social-icon-link"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </div>
    </div>
  );
}

export default FindMe;
