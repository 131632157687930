import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTiktok,
  faYoutube,
  faFacebook,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <div className="footer-component">
      <p>
        &copy; Joseph Bertone Real Estate 2023 All rights reserved. Toronto Real
        Estate Board (TREB) assumes no responsibility for the accuracy of any
        information shown. The information provided herein must only be used by
        consumers that have a bona fide interest in the purchase, sale or lease
        of real estate and may not be used for any commercial purpose or any
        other purpose.
      </p>
      <a href="/privacy-policy">Privacy Policy</a>
      <div className="social-icon-links-container">
        <a
          href="https://instagram.com/josephbertonerealestate"
          target="_blank"
          rel="noreferrer"
          className="social-icon-link"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          href="https://tiktok.com/@josephbertonerealty"
          target="_blank"
          rel="noreferrer"
          className="social-icon-link"
        >
          <FontAwesomeIcon icon={faTiktok} />
        </a>
        <a
          href="https://youtube.com/@Josephbertonerealestate"
          target="_blank"
          rel="noreferrer"
          className="social-icon-link"
        >
          <FontAwesomeIcon icon={faYoutube} />
        </a>
        <a
          href="https://facebook.com/profile.php?id=100088890105622"
          target="_blank"
          rel="noreferrer"
          className="social-icon-link"
        >
          <FontAwesomeIcon icon={faFacebook} />
        </a>
        <a
          href="https://linkedin.com/in/joseph-bertone-760325122/"
          target="_blank"
          rel="noreferrer"
          className="social-icon-link"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </div>
    </div>
  );
}

export default Footer;
