import React from "react";

function About() {
  return (
    <div className="about-component">
      <h2>Meet Joseph!</h2>
      <p>
        In today's market, there is no time for a salesman approach. A slimy
        salesman in a cheap suit trying to get you into any deal for a
        commission check; that's not how I run my business. I run things
        differently &mdash; no pressure deals. I'll provide the facts, and
        together we will make a solid plan of action whether you are buying,
        selling, or renting. Welcome to modern-day real estate! Please take a
        seat, and let's become friends.
      </p>
      <br />
      <p>
        My job breaks into friendship, knowledge, and negotiation when you
        become my client.
      </p>
      <br />
      <p>
        As a friend, I'll create open and honest lines of communication. You can
        reach me anytime, and rest assured I have your best interest at heart by
        providing you with my complete support. In contrast, we have a deal
        going on but afterward as well.
      </p>
      <br />
      <p>
        Knowledge is power, and this is why it has its category. My passion is
        real estate, so with passion comes the ability to learn up-to-date facts
        about the market quickly and easily. Do you have a question? I'll have
        an answer. And if I don't have an answer, I'll find one for you.
      </p>
      <br />
      <p>
        Negotiation. Since we're friends, I have your best interest in mind.
        Since I have knowledge and experience, that means I have ammunition.
        Both are vital ingredients in a negotiation because they get you the
        best deal! One where you feel glad to have me on your side but surprised
        about how painless the whole process was.
      </p>
      <br />
      <p>
        So as you can see, this is more than just a job for me. I am not chasing
        after your money. I genuinely want to work with you and show you this
        experience is fun and painless. I'm trying to turn houses into homes.
      </p>
    </div>
  );
}

export default About;
