import React from "react";
import navbarLogoImg from "../assets/images/jbre-navbar-logo.jpg";
import ContactHeader from "./ContactHeader";

function Navbar() {
  return (
    <div className="navbar-component">
      <ContactHeader />
      <nav className="navbar navbar-expand-lg bg-body-tertiary navbar-dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img
              alt="Click to load home page"
              src={navbarLogoImg}
              className="navbar-logo"
            />
          </a>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="toggler-icon top"></span>
            <span className="toggler-icon middle"></span>
            <span className="toggler-icon bottom"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/about">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/booking">
                  Book an Appointment
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/social-media">
                  Social Media
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
