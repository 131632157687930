import React from "react";
import PrivacyPolicy from "../PrivacyPolicy";

function PrivacyPolicyPage() {
  return (
    <div className="privacy-policy-page">
      <PrivacyPolicy />
    </div>
  );
}

export default PrivacyPolicyPage;
