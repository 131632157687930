import React from "react";
import Banner from "../Banner";
import FeaturedVideo from "../FeaturedVideo";
import FindMe from "../FindMe";
import Services from "../Services";

function HomePage() {
  return (
    <div className="home-page">
      <Banner />
      <Services />
      <FeaturedVideo />
      <FindMe />
    </div>
  );
}

export default HomePage;
