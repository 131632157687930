import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import axios from "axios";
import { ClipLoader } from "react-spinners";

function ContactForm() {
  const formRef = useRef();
  const [formState, setFormState] = useState({
    fname: "",
    lname: "",
    subject: "",
    howDidYouHear: "",
    email: "",
    message: "",
    consent: false,
  });
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
        process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID,
        formRef.current,
        process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          setSubmitted(true);
          setLoading(false);
          addEmailToMailChimpList();
        },
        (error) => {
          console.log(error.text);
          setLoading(false);
        }
      );
  };

  const addEmailToMailChimpList = () => {
    const body = {
      email_address: formState.email,
      status: formState.consent ? "subscribed" : "unsubscribed",
    };
    axios.post(process.env.REACT_APP_API_BASE_URL + "/api/v1/mailchimp", body);
  };

  const handleChange = (event) => {
    if (event.target.type === "checkbox") {
      setFormState({
        ...formState,
        consent: !formState.consent,
      });
    } else {
      setFormState({
        ...formState,
        [event.target.id]: event.target.value,
      });
    }
  };

  return (
    <div className="contact-form">
      {!loading && !submitted && (
        <form ref={formRef} onSubmit={handleSubmit}>
          <label htmlFor="fname" required>
            First name *:
          </label>
          <br />
          <input
            type="text"
            id="fname"
            name="fname"
            value={formState.fname}
            onChange={handleChange}
            required
          />
          <br />
          <label htmlFor="lname">Last name *:</label>
          <br />
          <input
            type="text"
            id="lname"
            name="lname"
            value={formState.lname}
            onChange={handleChange}
            required
          />
          <br />
          <label htmlFor="email">Email Address *:</label>
          <br />
          <input
            type="email"
            id="email"
            name="email"
            value={formState.email}
            onChange={handleChange}
            required
          />
          <br />
          <label htmlFor="email">Subject:</label>
          <br />
          <select name="subject" id="subject">
            <option value="Buy With Me">Buy With Me</option>
            <option value="Sell With Me">Sell With Me</option>
            <option value="Other">Other</option>
          </select>
          <br />
          <label htmlFor="email">How did you hear about me?</label>
          <br />
          <select name="how-did-you-hear" id="how-did-you-hear">
            <option value="instagram">Instagram</option>
            <option value="tiktok">TikTok</option>
            <option value="facebook">Facebook</option>
            <option value="referral">Referral</option>
            <option value="google">Google Search</option>
          </select>
          <br />
          <label htmlFor="message">Message *:</label>
          <br />
          <textarea
            id="message"
            name="message"
            value={formState.message}
            onChange={handleChange}
            required
          />
          <br />
          <input
            type="checkbox"
            id="consent"
            name="consent"
            value={formState.consent}
            onChange={handleChange}
          />
          <label htmlFor="consent">
            I consent to receiving promotional emails from Joseph Bertone Real
            Estate
          </label>
          <br />
          <input
            type="hidden"
            id="consent-value"
            name="consent-value"
            value={formState.consent ? "Yes" : "No"}
          />
          <input type="submit" value="Submit" />
        </form>
      )}
      <div className="form-result-actions">
        {loading && <ClipLoader />}
        {submitted && (
          <div>
            <h3>Message submitted successfully!</h3>
            <p>We will get back to you soon</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ContactForm;
