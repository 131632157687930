import React, { useEffect } from "react";

function Calendly() {
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);
  }, []);

  return (
    <div className="calendly-component">
      <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/josephbertone"
      ></div>
    </div>
  );
}

export default Calendly;
