import React from "react";
import bannerImg from "../assets/images/jbre-banner.jpg";

function Banner() {
  return (
    <div className="banner-component">
      <img alt="Joseph Bertone Realty banner" src={bannerImg} />
    </div>
  );
}

export default Banner;
