import React from "react";

function FacebookEmbed() {
  return (
    <div
      className="fb-page"
      data-href="https://www.facebook.com/profile.php?id=100088890105622"
      data-tabs="timeline"
      data-width="320"
      data-height="600"
      data-small-header="false"
      data-adapt-container-width="true"
      data-hide-cover="false"
      data-show-facepile="true"
    >
      <blockquote
        cite="https://www.facebook.com/profile.php?id=100088890105622"
        className="fb-xfbml-parse-ignore"
      >
        <a href="https://www.facebook.com/profile.php?id=100088890105622">
          Joseph Bertone
        </a>
      </blockquote>
    </div>
  );
}

export default FacebookEmbed;
