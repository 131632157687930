import React from "react";
import About from "../About";

function AboutPage() {
  return (
    <div className="about-page">
      <About />
    </div>
  );
}

export default AboutPage;
