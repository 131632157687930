import React, { useEffect } from "react";
import buyImg from "../assets/images/buy-icon.jpg";
import sellImg from "../assets/images/sell-icon.jpg";
import rentLeaseImg from "../assets/images/rent-lease-icon.jpg";

function Services() {
  useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    head.appendChild(script);
  }, []);

  return (
    <div className="services-component">
      <h2>Our Services</h2>
      <div className="row">
        <div className="col-md-4">
          <a
            href="#services"
            onClick={() =>
              window.Calendly.initPopupWidget({
                url: "https://calendly.com/josephbertone",
              })
            }
          >
            <img alt="Click to book an appointment to buy" src={buyImg} />
          </a>
        </div>
        <div className="col-md-4">
          <a
            href="#services"
            onClick={() =>
              window.Calendly.initPopupWidget({
                url: "https://calendly.com/josephbertone",
              })
            }
          >
            <img alt="Click to book an appointment to sell" src={sellImg} />
          </a>
        </div>
        <div className="col-md-4">
          <a
            href="#services"
            onClick={() =>
              window.Calendly.initPopupWidget({
                url: "https://calendly.com/josephbertone",
              })
            }
          >
            <img
              alt="Click to book an appointment to rent or lease"
              src={rentLeaseImg}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Services;
