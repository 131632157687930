import React from "react";
import ContactForm from "../ContactForm";
import linkedinImg from "../../assets/images/jbre-linkedin.jpg";

function ContactPage() {
  return (
    <div className="contact-page">
      <h2>Contact Joseph Bertone</h2>
      <div className="row">
        <div className="col-md-8 contact-section">
          <ContactForm />
        </div>
        <div className="col-md-4 contact-image">
          <img alt="Joseph Bertone headshot" src={linkedinImg} />
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
